import Button from "@cloudscape-design/components/button"
import Container from "@cloudscape-design/components/container"
import Form from "@cloudscape-design/components/form"
import FormField from "@cloudscape-design/components/form-field"
import Input from "@cloudscape-design/components/input"
import SpaceBetween from "@cloudscape-design/components/space-between"
import DatePicker from "@cloudscape-design/components/date-picker"
import TimeInput from "@cloudscape-design/components/time-input"
import Select from "@cloudscape-design/components/select"
import React, { useEffect, useState } from "react"
import axios from 'axios'
import { Alert } from "@cloudscape-design/components"
import { useNavigate } from "react-router-dom"


export default function CreateMonitor() {
    const [date, setDate] = useState("")
    const [startTime, setStartTime] = useState("00:00")
    const [endTime, setEndTime] = useState("23:59")
    const [golfers, setGolfers] = useState(4);
    const [course, setCourse] = useState(undefined)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [dateError, setDateError] = useState("")
    const [startTimeError, setStartTimeError] = useState("")
    const [endTimeError, setEndTimeError] = useState("")
    const [golfersError, setGolfersError] = useState("");
    const [courseError, setCourseError] = useState("")

    const [courses, setCourses] = useState([]);

    const navigate = useNavigate()

    async function createMonitor(event) {
        event.preventDefault();

        let hasError = false;
        if (date === undefined || date === "") {
            setDateError("Date is required")
            hasError = true
        } else {
            setDateError("")
        }
        
        if (startTime === undefined || startTime === "") {
            setStartTimeError("Start Time is required")
            hasError = true
        } else {
            setStartTimeError("")
        }

        if (endTime === undefined || endTime === "") {
            setEndTimeError("End Time is required")
            hasError = true
        } else {
            setEndTimeError("")
        }
        
        if (golfers === undefined || golfers === "") {
            setGolfersError("Golfers is required")
            hasError = true
        } else {
            setGolfersError("")
        }
        
        if (course === undefined || course.value === undefined || course.value === "") {
            setCourseError("Course is required")
            hasError = true
        } else {
            setCourseError("")
        }

        if (hasError) {
            return
        }

        try {
            await axios.post("https://api.fairwayfinder-dev.bryangardner.dev/api/monitors", {
                "courseId": course.value,
                "date": date,
                "startTime": startTime,
                "endTime": endTime,
                "golfers": parseInt(golfers)
            }, { withCredentials: true });
            setSuccess(true);
        } catch (err) {
            console.error('Failed to save monitor', err)
            setError(true)
        }
    }

    async function createCancel(event) {
        event.preventDefault();
        navigate('/')
    }

    useEffect(() => {
        async function getCourses() {
            try {
                const { data } = await axios.get('https://api.fairwayfinder-dev.bryangardner.dev/api/courses', { withCredentials: true });
                setCourses(data.courses.map((course) => {
                    return {
                        value: course.courseId, label: course.courseName
                    }
                }));
            } catch (error) {
                console.error('Failed to get courses', error);
            }
        }
        getCourses();
    }, [])

    return (<SpaceBetween direction="vertical" size="l">
        <form onSubmit={createMonitor}>
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="primary" target="submit">Create</Button>
                </SpaceBetween>
            } secondaryActions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={createCancel}>Cancel</Button>
                </SpaceBetween>
            }>
                <Container>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Date" errorText={dateError}>
                            <DatePicker onChange={({ detail }) => setDate(detail.value)}
                                value={date}
                                placeholder="YYYY-MM-DD"
                            />
                        </FormField>
                        <FormField label="Start Time" errorText={startTimeError}>
                            <TimeInput
                                onChange={({ detail }) => setStartTime(detail.value)}
                                value={startTime}
                                format="hh:mm"
                                placeholder="hh:mm"
                            />
                        </FormField>
                        <FormField label="End Time" errorText={endTimeError}>
                            <TimeInput
                                onChange={({ detail }) => setEndTime(detail.value)}
                                value={endTime}
                                format="hh:mm"
                                placeholder="hh:mm"
                            />
                        </FormField>
                        <FormField label="Golfers" errorText={golfersError}>
                            <Input type="number" value={golfers} onChange={({ detail }) => {
                                if (detail.value === '') {
                                    setGolfers(undefined)
                                } else if (detail.value > 0 && detail.value < 5) {
                                    setGolfers(detail.value)
                                }
                            }} />
                        </FormField>
                        <FormField label="Course" errorText={courseError}>
                            <Select
                                selectedOption={course}
                                onChange={({ detail }) => { setCourse(detail.selectedOption) }
                                }
                                options={courses}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
        {success ?
            <Alert type="success" header='Success' dismissible={true} onDismiss={e => setSuccess(false)}>Successfully saved monitor. Go to <Button href="/" variant='inline-link'>Monitors</Button>.</Alert> : null}
        {error ?
            <Alert type="error" header='Error' dismissAriaLabel={true} onDismiss={e => setError(false)}>Failed to save monitor.</Alert> : null}
    </SpaceBetween>)
}