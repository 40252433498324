import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Monitors from './components/monitors';
import "@cloudscape-design/global-styles/index.css";
import CreateMonitor from './components/create_monitor';
import {Alert, Button, ContentLayout, Header, SpaceBetween} from '@cloudscape-design/components';
import axios from 'axios';

function Layout() {
  const [authorized, setAuthorized] = useState(undefined)

  useEffect(() => {
    async function getIdentity() {
      try {
        await axios.get("https://api.fairwayfinder-dev.bryangardner.dev/api/userInfo", { withCredentials: true });
        setAuthorized(true)
      } catch (error) {
        console.log(error);
        if ([401, 403].indexOf(error.response?.status)) {
          setAuthorized(false)
        }
      }
    }
    getIdentity();
  }, [])
  return (
    <ContentLayout defaultPadding header={
      <SpaceBetween size='m'>
        <Header variant='h1'>Fairway Finder</Header>
        {authorized === false && <Alert header='Unauthorized' type='error'>Continue to <Button href="/auth" variant='inline-link'>Login</Button>.</Alert>}
      </SpaceBetween>
    }>
      <SpaceBetween size='l'>
        <Outlet context={[authorized, setAuthorized]} />
      </SpaceBetween>
    </ContentLayout>
  )
}


const router = createBrowserRouter([
  {
    children: [
      {
        path: "/",
        element: <Monitors />
      },
      {
        path: "create-monitor",
        element: <CreateMonitor />
      }
    ],
    element: <Layout></Layout>,
  }
]);

export const routes = router.routes;

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ContentLayout defaultPadding>
      <RouterProvider router={router} />
    </ContentLayout>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
