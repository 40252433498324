import {
    Button,
    ButtonDropdown,
    Cards, Header,
    SpaceBetween
} from '@cloudscape-design/components';
import Box from "@cloudscape-design/components/box";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Monitors() {
    const [loading, setLoading] = useState(true);
    const [monitors, setMonitors] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        async function getMonitors() {
            try {
                const getMonitorsResponse = await axios.get('https://api.fairwayfinder-dev.bryangardner.dev/api/monitors', { withCredentials: true });
                setMonitors(getMonitorsResponse.data.monitors);
            } catch (error) {
                console.error('Failed to get monitors', error);
            } finally {
                setLoading(false);
            }
        }
        getMonitors();
    }, [])

    const deleteMonitor = async (id) => {
        try {
            await axios.delete(`https://api.fairwayfinder-dev.bryangardner.dev/api/monitors/${id}`, { withCredentials: true });
            setMonitors(monitors.filter(monitor => monitor.id !== id))
        } catch (error) {
            console.error('Failed to delete monitor', error)
        }
    }

    return <Cards
        header={
            <Header actions={<SpaceBetween direction='horizontal' size='xs'>
                <Button variant='primary' onClick={e => navigate('/create-monitor')}>Create Monitor</Button>
            </SpaceBetween>}>My Monitors</Header>
        }
        cardDefinition={{
            header: item => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <span>{item.course.courseName}</span>
                  <ButtonDropdown
                    items={[
                      {
                        text: "Delete",
                        id: "rm",
                        disabled: false
                      },
                    ]}
                    onItemClick={async ({ detail }) => {
                        if (detail.id === 'rm') {
                            await deleteMonitor(item.id)
                        }
                    }}
                    ariaLabel="Control instance"
                    variant="inline-icon"
                  />
                </div>
              ),
            sections: [
                {
                    id: 'id',
                    header: 'Monitor ID',
                    content: item => item.id
                },
                {
                    id: 'date',
                    header: 'Date',
                    content: item => item.date
                },
                {
                    id: 'golfers',
                    header: 'Golfers',
                    content: item => item.golfers
                },
                {
                    id: 'startTime',
                    header: 'Start Time',
                    content: item => item.startTime
                },
                {
                    id: 'endTime',
                    header: 'End Time',
                    content: item => item.endTime
                }
            ]
        }}
        visibleSections={['date', 'golfers', 'startTime', 'endTime', 'actions']}
        items={monitors}
        trackBy='id'
        empty={
            <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
            >
                <SpaceBetween size="m">
                    <b>No monitors</b>
                </SpaceBetween>
            </Box>
        }
        loading={loading}
        loadingText='Loading Monitors'>
    </Cards>
}